import React, { Component } from 'react';
import { GoogleApiWrapper, Map, Circle, InfoWindow } from 'google-maps-react';
import {LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

const API_LATLNG = 'https://corona.tmsoftwareinc.com/api/v1/latlngs?lat=';
const API_ADR = 'https://corona.tmsoftwareinc.com/api/v1/addresses?country=';
const API_GEO = 'https://corona.tmsoftwareinc.com/api/v1/geos?location=';
const API_BOUNDS = 'https://corona.tmsoftwareinc.com/api/v1/jabounds';
// const API_BOUNDS = 'http://192.168.2.2:3000/v1/bounds?north=';

let prefs = {
  Hokkaido: "北海道",
  Aomori: "青森県",
  Iwate: "岩手県",
  Miyagi: "宮城県",
  Akita: "秋田県",
  Yamagata: "山形県",
  Fukushima: "福島県",
  Ibaraki: "茨城県",
  Tochigi: "栃木県",
  Gunma: "群馬県",
  Saitama: "埼玉県",
  Chiba: "千葉県",
  Tokyo: "東京都",
  Kanagawa: "神奈川県",
  Niigata: "新潟県",
  Toyama: "富山県",
  Ishikawa: "石川県",
  Fukui: "福井県",
  Yamanashi: "山梨県",
  Nagano: "長野県",
  Gifu: "岐阜県",
  Shizuoka: "静岡県",
  Aichi: "愛知県",
  Mie: "三重県",
  Shiga: "滋賀県",
  Kyoto: "京都府",
  Osaka: "大阪府",
  Hyogo: "兵庫県",
  Nara: "奈良県",
  Wakayama: "和歌山県",
  Tottori: "鳥取県",
  Shimane: "島根県",
  Okayama: "岡山県",
  Hiroshima: "広島県",
  Yamaguchi: "山口県",
  Tokushima: "徳島県",
  Kagawa: "香川県",
  Ehime: "愛媛県",
  Kochi: "高知県",
  Fukuoka: "福岡県",
  Saga: "佐賀県",
  Nagasaki: "長崎県",
  Kumamoto: "熊本県",
  Oita: "大分県",
  Miyazaki: "宮崎県",
  Kagoshima: "鹿児島県",
  Okinawa: "沖縄県",
  Japan: "日本"
};
/*
let caseCircles = [
    {
      city: 'Studio City',
      county: 'Los Angeles',
      state: 'California',
      country: 'us',
      cases: 200,
      lat: 34.139619,
      lng: -118.38
    },
    {
      city: 'glendale',
      county: 'Los Angeles',
      state: 'California',
      country: 'us',
      cases: 100,
      lat: 34.139619,
      lng: -118.43
    },
    {
      city: 'Van nuyes',
      county: 'Los Angeles',
      state: 'California',
      country: 'us',
      cases: 3000,
      lat: 34.139619,
      lng: -118.55
    },
  ];
  */
  

class GoogleMap extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    // HW Upper School
    lat: 35.68055206845443,
    lng: 139.75779607476056,
    zoom: 6,
    city: null,
    county: null,
    state: null,
    country: null,
    date: null,
    cases: null,
    newcases: null,
    sevenrate: null,
    cityJsonData: null,
    countyJsonData: null,
    stateJsonData: null,
    countryJsonData: null,
    cityTabDisabled: false,
    countyTabDisabled: false,
    stateTabDisabled: false,
    countryTabDisabled: false,
    tabIndex: 0,
    usage: 'Usage: Click (Tap) to see data there.',
    showingInfoWindowCircle: false,
    latCircle: null,
    lngCircle: null,
    caseCircles: [],
    circleScale: 10000,
    boundNorth: null,
    boundSouth: null,
    boundEast: null,
    boundWest: null,
  }

  searchLocation = 'Harvard-Westlake Upper School';
  chartHeight = 100;

  getCases() {
    // this.state.lat, lng: this.state.lng
    fetch(API_LATLNG+this.state.lat+'&long='+this.state.lng)
    .then(res => res.json())
    .then((data) => {
      if(data.length === 0) {
        this.setState({
          city: null,
          county: null,
          state: null,
          country: null,
          date: null,
          cases: 'データがありません',
          newcases: null,
          sevenrate: null,
          showingInfoWindow: true,
          activeMarker: {},
          cityJsonData: null,
          countyJsonData: null,
          stateJsonData: null,
          countryJsonData: null,
          tabIndex: 0,
          cityTabDisabled: false,
          countyTabDisabled: false,
          stateTabDisabled: false,
          countryTabDisabled: false,
        });
      }
      else {
        if(data[0]['country'] === 'us' && data[0]['county'] !=='New York City') {
          this.setState({county: data[0]['county']+ ' County'})
        }
        else {
          this.setState({county: data[0]['county']})
        }
  // city existence is assumed at first
      this.setState({
        city: data[0]['city'],
  //      county: data[0]['county'],
        state: data[0]['state'],
        country: data[0]['country'],
        date: data[0]['date'],
        cases: data[0]['cases'],
        newcases: '新規感染者 ' + data[0]['newcases'],
        sevenrate: '新規感染者率 ' + data[0]['7rate'],
        showingInfoWindow: true,
        activeMarker: {},
        cityJsonData: data,
        countyJsonData: null,
        stateJsonData: null,
        countryJsonData: null,
        tabIndex: 0,
        cityTabDisabled: false,
        countyTabDisabled: false,
        stateTabDisabled: false,
        countryTabDisabled: false,
      });
      if(data[0]['newcases'] === -1) {
        this.setState({
          newcases: ''
        });
      }
      if(data[0]['7rate'] === -1) {
        this.setState({
          sevenrate: ''
        });
      }
      if(prefs[data[0]['state']]) {
        this.setState({
          state: prefs[data[0]['state']]
        });
      }
      if(data[0]['country'] === 'Japan') {
        this.setState({
          country: '',
          countryTabDisabled: true
        });
      }
 // Move json Data step by step     
      if(data[0]['city'] === '') {
        this.setState({
          tabIndex: 1,
          cityTabDisabled: true,
          cityJsonData: null,
          countyJsonData: data
        });
        if(data[0]['county'] === '') {
          this.setState({
            tabIndex: 2,
            countyTabDisabled: true,
            countyJsonData: null,
            stateJsonData: data
          });
          if(data[0]['state'] === '') {
            this.setState({
              tabIndex: 3,
              stateTabDisabled: true,
              stateJsonData: null,
              countryJsonData: data
            });
          }
          else {
            if(data[0]['country'] !== 'Japan') {
            fetch(API_ADR+data[0]['country'])
            .then(res4 => res4.json())
            .then((data4) => {
              this.setState({
                countryTabDisabled: false,
                countryJsonData: data4
              });
            });
          }
          }
        } 
        else {
// Get state data
          fetch(API_ADR+data[0]['country']+'&state='+data[0]['state'])
          .then(res3 => res3.json())
          .then((data3) => {
            this.setState({
              countryTabDisabled: true,
              stateJsonData: data3
            });
          });
        }
      }
      else {
        // Get county data
        fetch(API_ADR+data[0]['country']+'&state='+data[0]['state']+'&county='+data[0]['county'])
        .then(res2 => res2.json())
        .then((data2) => {
        this.setState({
          tabIndex: 0,
          countyJsonData: data2
          });
        });

        // Get state data
        fetch(API_ADR+data[0]['country']+'&state='+data[0]['state'])
        .then(res3 => res3.json())
        .then((data3) => {
        this.setState({
          tabIndex: 0,
          countryTabDisabled: true,
          stateJsonData: data3
          });
        });
      }
      }
    })
    .catch(console.log)
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });
      this.getCases();
    },
    (err) => {
      this.getCases();
      console.log(err);
    })
  }

  mapClicked = (mapProps, map, event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.setState({
      lat: lat,
      lng: lng
    });
    this.getCases();
  }

  mapReady = (mapProps, map, event) => {
  //  let rect = map.getBounds();
  
//    console.log('idle Evented');
//    console.log('idle getBounds= '+rect.getSouthWest()+':'+rect.getNorthEast());
    /*
    console.log('idle getBounds= '+rect.getSouthWest().lat()+':'+rect.getSouthWest().lng());
    console.log('idle getBounds= '+rect.getNorthEast().lat()+':'+rect.getNorthEast().lng());
    */
//    let north = rect.getNorthEast().lat();
//    let east = rect.getNorthEast().lng();
//    let south = rect.getSouthWest().lat();
//    let west = rect.getSouthWest().lng();
//    console.log(north, ' ', south, ' ', east, ' ' ,west);
    /*
    if(this.state.boundNorth === north && this.state.boundSouth === south && this.state.boundEast === east && this.state.boundWest === west) {
      return;
    }
    else {
      this.setState({
        oundNorth: north,
        boundSouth: south,
        boundEast: east,
        boundWest: west,
      });
    }
    */
    let north = 0;
    let east = 0;
    let south = 0;
    let west = 0;
//    let url = API_BOUNDS+north+'&south='+south+'&east='+east+'&west='+west;
    let url = API_BOUNDS;
    console.log(url);
    fetch(url)
        .then(res => res.json())
        .then((data) => {
          
          this.setState({
            caseCircles: data
          });

          // Convert to Japanese prefs name
          this.state.caseCircles.forEach((item) => {
            item.state = prefs[item.state]
          })

          // Sort caseCircles by sevenrate
          this.state.caseCircles.sort((a,b) => b.sevenrate-a.sevenrate );
    });

  }

  onMarkerClick = (props, marker, e) => {
    this.setState({
      activeMarker: marker
    });
  }

  onSearchLocationKeyPress = (event) => {
    if(event.keyCode === 13) {
      this.onSearchButtonClick();
    }
  }

  onSearchLocationChange = (event) => {
    this.searchLocation = event.target.value;
  }

  onSearchButtonClick = (event) => {
    fetch(API_GEO+this.searchLocation)
    .then(res => res.json())
    .then((data) => {
      this.setState({
        lat: data['lat'],
        lng: data['lng'],
        zoom: 12,
        showingInfoWindow: false
      });
      this.getCases();
    });
  }

  //showCasesCircle(show, lat, lng, cases) {
  //  this.setState({
  //    showingInfoWindowCircle: show,
  //    latCircle: lat,
  //    lngCircle: lng,
  //    casesCircle: cases
  //  });
  // }

  render() {
    return (
      <div>
        <div class="w-section section ">
        <div class="mobilenoshow centering">新型コロナ感染者数データを知りたい場所を地図上でクリックしてください</div>
        <div class="mobilenoshow centering">新規感染者率が多い場所ほど赤い円が大きくなっています</div>
        <div class="mobilenoshow centering">新規感染者率は人口１０万人あたりの新規感染者数の７日間移動平均です</div>
        <div class="mobileshow centering">感染者数データを知りたい場所を地図上でタップ</div>
        <div class="mobileshow centering">新規感染者率=新規感染者数/日/10万人</div>
        <div class="w-container centering">
        <input type='text' size="30" placeholder="都道府県または場所名" onChange={this.onSearchLocationChange} onKeyDown={this.onSearchLocationKeyPress} />&nbsp;
        <button class="button small-button" onClick={this.onSearchButtonClick}>検索</button>
        </div>

        <div class="mobilenoshow centering">
          <h3>日本国内の１日あたりの新規感染者数と新規感染者率</h3>
    <table>
    <thead>
    <tr><th class="th_country">都道府県</th><th class="th_newcases">新規感染者数(人)</th><th class="th_caserate">新規感染者率(人)</th><th class="th_caserate">日付</th></tr>
    </thead>
    <tbody>
    {this.state.caseCircles.map((item,key) => {
          return <tr><td class="td_country">{item.state}</td><td class="td_newcases">{item.newcases}</td><td class="td_caserate">{item.sevenrate}</td><td class="td_caserate">{item.date.substring(0,10)}</td></tr>
    }
    )}
    </tbody>
    </table>
    </div>

      </div>
      <br />
      <div class="w-section section purple " id="chart">
      <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })} >
      <TabList>
      <Tab disabledClassName="react-tabs__tab--disabled-hidden" disabled={this.state.cityTabDisabled} >{this.state.city}</Tab>
      <Tab disabledClassName="react-tabs__tab--disabled-hidden" disabled={this.state.countyTabDisabled}>{this.state.county}</Tab>
      <Tab disabledClassName="react-tabs__tab--disabled-hidden" disabled={this.state.stateTabDisabled}>{this.state.state}</Tab>
      <Tab disabledClassName="react-tabs__tab--disabled-hidden" disabled={this.state.countryTabDisabled}>{this.state.country}</Tab>
    </TabList>
      <TabPanel>
      <ResponsiveContainer width="95%" height={this.chartHeight}>
        <LineChart data={this.state.cityJsonData}>
        <XAxis dataKey="date" reversed/>
        <YAxis/>
        <Line type="monotone" dataKey="cases" stroke="#8884d8" />
        <Tooltip />
        </LineChart>
      </ResponsiveContainer>
      </TabPanel>
      <TabPanel>
      <ResponsiveContainer width="95%" height={this.chartHeight}>
        <LineChart data={this.state.countyJsonData}>
        <XAxis dataKey="date" reversed/>
        <YAxis/>
        <Line type="monotone" dataKey="cases" stroke="#8884d8" />
        <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </TabPanel>
    <TabPanel>
      <ResponsiveContainer width="95%" height={this.chartHeight}>
        <LineChart data={this.state.stateJsonData}>
        <XAxis dataKey="date" reversed/>
        <YAxis/>
        <Line type="monotone" dataKey="cases" stroke="#8884d8" />
        <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </TabPanel>
    <TabPanel>
      <ResponsiveContainer width="95%" height={this.chartHeight}>
        <LineChart data={this.state.countryJsonData}>
        <XAxis dataKey="date" reversed/>
        <YAxis/>
        <Line type="monotone" dataKey="cases" stroke="#8884d8" />
        <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </TabPanel>
      </Tabs>
      </div>
      <Map
        google = { this.props.google }
        zoom = { this.state.zoom }
        style={{width: '100%', height: '100%', position: 'static'}}
        center = {{ lat: this.state.lat, lng: this.state.lng }}
        initialCenter = {{ lat: this.state.lat, lng: this.state.lng }}
        zoomControlOptions = { {position: this.props.google.maps.ControlPosition.TOP_RIGHT} }
        StreetViewControl = { false }
        onClick={this.mapClicked}
        onReady={this.mapReady}
      >
        {this.state.caseCircles.map((item) => (
          <Circle
          radius={item.sevenrate*this.state.circleScale}
          center = {{ lat: item.lat, lng: item.lng }}
          onClick={this.mapClicked}
          strokeColor='transparent'
          strokeOpacity={0}
          strokeWeight={5}
          fillColor='#FF0000'
          fillOpacity={0.2}
        />
        ))}
        
        <InfoWindow
          visible = { this.state.showingInfoWindow }
          position = {{ lat: this.state.lat, lng: this.state.lng }}
        >
          <div>
            <h4>{this.state.state} {this.state.country}<br /> {this.state.date}<br />
        総感染者 {this.state.cases}&nbsp;&nbsp;{this.state.newcases}&nbsp;&nbsp;{this.state.sevenrate}<br />
        </h4>
          </div>
        </InfoWindow>
      </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_MAP_API_KEY)
})(GoogleMap);